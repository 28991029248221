import React, { useState, useEffect } from "react";
import {
  Header,
  List,
  Card,
  Icon,
  Loader,
  Dropdown,
  Segment,
  Button,
  Grid,
  Divider,
} from "semantic-ui-react";
import { Link } from "gatsby";

import { useDispatch, useSelector } from "react-redux";
import {
  fetchTiles,
  fetchPages,
  fetchSections,
  update_Page,
  fetchJunctionPages,
  fetchPathways,
  fetchImages,
} from "../../actions";

import { Slider } from "./components";

import MobileTiles from "../mobile/Tiles/MobileTiles";
import PreviewBar from "./previewBar";

import _ from "lodash";

import "../mobile/Tiles/mobile.scss";

const Structure = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const user = useSelector((state) => state.user);
  const trusts = useSelector((state) => state.trusts);
  const images = useSelector((state) => state.images);
  //local state
  const [pathway_object_dropdown, update_pathway_object_dropdown] =
    useState(false);
  const [pathwaySelection, update_pathwaySelection] = useState(null);
  const [loading, update_loading] = useState(true);
  const [error_count, update_error_count] = useState(0);

  const [slider, setSlider] = useState(1);

  const [preview_origin, update_preview_origin] = useState(null);
  const [preview_page_sections, update_preview_page_sections] = useState([]);
  const [display_preview, update_display_preview] = useState(0);

  const [selected_page_id, update_selected_page_id] = useState(null);
  const [timeline_data, update_timeline_data] = useState(null);

  var link_depth = 0;
  const colours = {
    blue: "#27B2F3",
    red: "#D22D3C",
    orange: "#FB8B24",
    purple: "#AC6CEE",
  };

  useEffect(() => {
    dispatch(fetchTiles(state.user["custom:trust"]));
    dispatch(fetchPages(state.user["custom:trust"]));
    dispatch(fetchSections(state.user["custom:trust"]));
    dispatch(fetchJunctionPages(state.user["custom:trust"]));
    dispatch(fetchPathways(state.user["custom:trust"]));
    dispatch(fetchImages());
  }, []);

  useEffect(() => {
    updateDropDown();
  }, [trusts]);

  useEffect(() => {
    updateDropDown();
  }, [state.pathways]);

  function updateDropDown() {
    const result = createPathwayObject();
    update_pathway_object_dropdown(result);
    const trust = _.find(trusts, { trust_id: state.user["custom:trust"] });
    if (trust !== undefined) {
      update_pathwaySelection(trust.default_pathway);
      update_pathway_selection(trust.default_pathway);
      update_loading(false);
    }
  }

  function update_pathway_selection(value) {
    update_pathwaySelection(value);
    console.log(value);
    if (value === "None") {
      update_timeline_data(null);
    }

    const pathway_data = _.find(state.pathways, { id: value });
    if (pathway_data !== undefined) {
      const parsed_data = JSON.parse(pathway_data.timeline_data);
      update_timeline_data(parsed_data);
      console.log(parsed_data);
    }
  }

  //creates pathway object for dropdown
  const createPathwayObject = () => {
    console.log(state);
    let pathway_object = [];
    state.pathways.map((pathway) => {
      pathway_object.push({
        id: pathway.id,
        text: pathway.title,
        value: pathway.id,
      });
    });
    return pathway_object;
  };

  // updateErrorCount(){
  var myCount = 1;
  function updateErrorCount() {
    myCount = myCount + 1;
  }

  // Update page restrictions

  const handleUpdatePage = (page) => {
    //event.preventDefault();
    const restrict = !page.restrict_to_admin;
    const params = {
      Item: {
        id: page.id,
        index_number: page.index_number,
        page_id: page.page_id,
        timestamp: page.timestamp,
        title: page.title,
        restrict_to_admin: restrict,
      },
    };
    dispatch(update_Page(params, state.user["custom:trust"]));
  };

  function updatePageClick(page_id) {
    console.log(page_id);
    const updatedSections = _.filter(state.sections, { section_id: page_id });
    console.log(updatedSections);
    update_selected_page_id(page_id);
    update_preview_origin(null);
    update_preview_page_sections(updatedSections);
    if (slider === 1) {
      setSlider(0);
    }
  }

  function updateTileClick() {
    update_preview_origin("tiles");

    if (slider === 1) {
      setSlider(0);
    }
  }

  // Render components
  const renderTile = () => {
    const ordered_tiles = _.sortBy(state.tiles, ["index_number"]);
    const tiles = ordered_tiles.map((tile) => {
      link_depth = 0;
      return (
        <List.Item>
          <List.Icon name="list ul" />
          <List.Content>
            <List.Header onClick={() => updateTileClick()}>
              {tile.title}
              {tile.restrict_to_admin === true ? (
                // <div style={{ color: colours.blue }}>Restricted to admin</div>
                <></>
              ) : (
                <div></div>
              )}
            </List.Header>

            {tile.alternative_link === "Wellness" ? (
              <div
                style={{
                  width: 160,
                  marginTop: 5,
                  marginBottom: 5,
                  display: "inline-flex",
                  borderRadius: 10,
                  padding: 6,
                  paddingLeft: 10,
                  backgroundColor: colours.purple,
                  color: "white",
                }}
              >
                <div>
                  <Icon name="heartbeat" size={14} />
                </div>
                <div style={{ marginLeft: 10 }}>Wellness Tracker</div>
              </div>
            ) : (
              <></>
            )}
            {tile.alternative_link === "Timeline" ? (
              <div style={{ backgroundColor: "#f3f3f3", margin: 10 }}>
                <div
                  style={{
                    width: "100%",
                    marginBottom: 5,
                    display: "inline-flex",
                    padding: 8,
                    paddingLeft: 10,
                    backgroundColor: colours.orange,
                    color: "white",
                  }}
                >
                  <div>
                    <Icon name="image outline" size={14} />
                  </div>
                  <div style={{ marginLeft: 10 }}>Timeline</div>
                </div>
                <div style={{ padding: 10 }}>{renderTimeline()}</div>
              </div>
            ) : (
              <></>
            )}
            {tile.destination === null &&
            tile.junction_page_selected !== true ? (
              <List.Description style={{ color: colours.red }}>
                THERE IS NO DESTINATION
              </List.Description>
            ) : (
              renderPage("tile", tile)
            )}
            {/* <Button onClick={() => console.log(tile)}>test</Button> */}
            <Divider />
          </List.Content>
        </List.Item>
      );
    });
    return <List>{tiles}</List>;
  };
  var indent_value = 20;
  var indent = 20;

  // THis renders the page
  function renderPage(type, object, junction_page) {
    console.log(link_depth);
    if (link_depth >= 200) {
      return (
        <div style={{ color: colours.red }}>
          You have a loop of links! {JSON.stringify(object)}
        </div>
      );
    }
    link_depth = link_depth + 1;
    var page;
    var destination = object.destination || object.page_id;
    var title = object.title;
    if (type == "tile") {
      if (
        object.alternative_link === "Wellness" ||
        object.alternative_link === "Timeline"
      ) {
        return;
      }
      const { junction_page_selected, JunctionPage } = object;
      if (junction_page_selected === true && pathwaySelection !== "None") {
        console.log("JunctionPage");
        console.log(JunctionPage);
        const find_page = returnRightPageForJunction(JunctionPage);
        page = _.find(state.pages, { page_id: destination });
        if (find_page !== undefined) {
          page = find_page;
        }
      } else {
        page = _.find(state.pages, { page_id: destination });
      }
    }
    if (type === "section") {
      console.log("asd");
      page = _.find(state.pages, { page_id: destination });
    }
    const filteredSections = _.filter(state.sections, {
      section_id: destination,
    });
    var icon = "file";
    if (page !== undefined) {
      if (page.user_id === "common") {
        icon = " share alternate";
      }
    }

    var link_colour = "black";
    var link_type = "";
    if (object.junction_page_selected || junction_page) {
      icon = "shuffle";
      link_colour = colours.blue;
      link_type = "  ( Junction Page Link )";
    }
    return (
      <div>
        {page === undefined ? (
          <div
            style={{ display: "inline-flex", paddingTop: 10, fontWeight: 600 }}
          >
            <div>{title}:</div>
            <div style={{ color: colours.red, paddingLeft: 5 }}>
              Warning: No destination assigned to link
            </div>
          </div>
        ) : (
          <List.List>
            <List.Item>
              <List.Icon style={{ color: link_colour }} name={icon} />
              <List.Content>
                {/* {JSON.stringify(page.user_id)} */}
                {renderPageLink(page.page_id, page, link_colour, link_type)}
                {filteredSections.map((section) => {
                  return renderSubSections(section);
                })}
              </List.Content>
            </List.Item>
          </List.List>
        )}
      </div>
    );
    return;
  }

  function returnRightPageForJunction(JunctionPage) {
    const find_junction_page = _.find(state.junctionPages, {
      id: JunctionPage,
    }); // find the junction page
    if (find_junction_page === undefined) {
      return;
    }
    const junction_data = JSON.parse(find_junction_page.junction_data); // parse the data
    // find the selected junction
    const find_page = _.find(junction_data, { id: pathwaySelection });
    return find_page;
  }

  function renderSubSections(section) {
    switch (section.type) {
      case "link":
        return renderPageLinks(section);

        break;
      case "cta":
        if (section.data === "internal") {
          return renderPageLinks(section);
        }
        return;
      case "info":
        if (section.data === "internal") {
          return renderPageLinks(section);
        }
        return;
      case "accordion":
        if (section.data === "internal") {
          return renderPageLinks(section);
        }
        return;
      // case "image":
      //   if (section.image_type === "external") {
      //     const find_image = _.find(images, {
      //       hash: section.image_hash,
      //     });
      //     if (!!find_image === false) {
      //       return (
      //         <div onClick={() => console.log(section)}>
      //           IMAGE NOT FOUND {section.image_hash}
      //         </div>
      //       );
      //     }
      //     return (
      //       <div>
      //         {find_image.hash}
      //         {find_image.original_image}
      //         {section.image_url}
      //       </div>
      //     );
      //   }
      // return;
      default:
        break;
    }
  }

  function renderPageLinks(section) {
    switch (section.page_link_type) {
      case "Normal":
        return renderPage("section", section);
        break;
      case "Timeline":
        return (
          <div style={{ backgroundColor: "#f3f3f3", margin: 10 }}>
            <div
              style={{
                width: "100%",
                marginBottom: 5,
                display: "inline-flex",
                padding: 8,
                paddingLeft: 10,
                backgroundColor: colours.orange,
                color: "white",
              }}
            >
              <div>
                <Icon name="image outline" size={14} />
              </div>
              <div style={{ marginLeft: 10 }}>Timeline</div>
            </div>
            <div style={{ padding: 10 }}>{renderTimeline()}</div>
          </div>
        );
        break;
      case "Wellness":
        return (
          <div
            style={{
              width: 160,
              marginTop: 5,
              marginBottom: 5,
              display: "inline-flex",
              borderRadius: 10,
              padding: 6,
              paddingLeft: 10,
              backgroundColor: colours.purple,
              color: "white",
            }}
          >
            <div>
              <Icon name="heartbeat" size={14} />
            </div>
            <div style={{ marginLeft: 10 }}>Wellness Tracker</div>
          </div>
        );
        break;
      case "Junction":
        var page;
        const find_page = returnRightPageForJunction(section.alternative_link);
        page = _.find(state.pages, { page_id: section.destination });
        if (find_page !== undefined) {
          page = find_page;
        }
        return (
          <div>
            {/* <div
              style={{
                width: 150,
                marginTop: 5,
                marginBottom: 5,
                display: "inline-flex",
                borderRadius: 10,
                padding: 6,
                paddingLeft: 10,
                backgroundColor: colours.blue,
                color: "white",
              }}
            >
              <div>
                <Icon name="image outline" size={14} />
              </div>
              <div style={{ marginLeft: 10 }}>Junction</div>
            </div> */}
            <div>{renderPage("section", page, true)}</div>
          </div>
        );
        break;
      default:
        break;
    }
  }

  const renderPageLink = (page_id, page, colour, link_type) => {
    var link_colour = colour || "black";
    var selected_link_type = link_type || "";
    var selected_page_id = page_id || page.destination;
    if (
      state.user["custom:level"] === "basic" &&
      page.restrict_to_admin === true
    ) {
      return (
        <List.Header style={{ color: link_colour }}>{page.title}</List.Header>
      );
    }
    return (
      <List.Header style={{ cursor: "pointer", color: link_colour }}>
        {/* <div onClick={() => updatePageClick(page_id, page)}> */}
        <div onClick={() => updatePageClick(selected_page_id, page)}>
          {page.title}
          {selected_link_type}
        </div>
      </List.Header>
    );
    //return <List.Header><Link to={"/sections?id=" + page_id}>{page.title}</Link>{renderAccessButton(page)}</List.Header>
  };

  const renderAccessButton = (page) => {
    if (state.user["custom:level"] !== "basic") {
      if (page.restrict_to_admin === true) {
        return (
          <div
            style={{
              display: "inline",
              paddingLeft: 5,
              color: "red",
              fontSize: 12,
              paddingLeft: 4,
              cursor: "pointer",
            }}
            onClick={() => handleUpdatePage(page)}
          >
            (Restricted)
          </div>
        );
      }
      return (
        <div
          style={{
            display: "inline",
            paddingLeft: 8,
            color: "green",
            fontSize: 12,
            paddingLeft: 4,
            cursor: "pointer",
          }}
          onClick={() => handleUpdatePage(page)}
        >
          (Unrestricted)
        </div>
      );
    }
  };

  function renderCards(timeline_section) {
    return timeline_section.card_array.map((card) => {
      return (
        <div>
          {/* <div>Card</div> */}
          <div>{renderPage("section", card)}</div>
        </div>
      );
    });
  }

  function renderTimeline() {
    if (timeline_data === null || timeline_data === undefined) {
      return;
    }

    if (timeline_data === null) {
      return;
    }
    return timeline_data.map((timeline_section) => {
      return (
        <div>
          <div style={{ fontWeight: 600 }}>{timeline_section.title}</div>
          <div style={{ marginLeft: 10 }}>{renderCards(timeline_section)}</div>
          <Divider />
        </div>
      );
    });
  }

  function updateSlider() {
    if (slider === 1) {
      setSlider(0);
      return;
    }
    setSlider(1);
  }

  if (link_depth > 10) {
    return <div> To0 big</div>;
  }

  return (
    <div style={{ display: "flex" }}>
      <div key={pathwaySelection}>
        <button onClick={() => console.log(images)}>Test Images</button>
        <Segment
          style={{ textAlign: "center", background: "#efefef", width: 500 }}
          color="blue"
        >
          <Button onClick={() => console.log(images)}>Test</Button>
          <h2>Pathway Selected</h2>
          <Dropdown
            placeholder="Filter For Pathway"
            fluid
            selection
            defaultValue={pathwaySelection}
            options={pathway_object_dropdown}
            onChange={(e, { value }) => update_pathway_selection(value)}
          />
        </Segment>
        <div>{renderTile()}</div>
      </div>
      <Slider
        slider={slider}
        preview_origin={preview_origin}
        state={state}
        preview_page_sections={preview_page_sections}
        updateSlider={updateSlider}
        selected_page_id={selected_page_id}
      />
    </div>
  );
};

export default Structure;
