import React from "react";
import MobileTiles from "../../mobile/Tiles/MobileTiles";
import { Button } from "semantic-ui-react";
import { Link } from "gatsby";

const Slider = (
{slider,
preview_origin,
state,
preview_page_sections,
updateSlider,
selected_page_id}
) => {
  return (
    <div className="preview" slider={slider}>
      <MobileTiles
        key={preview_origin}
        origin={preview_origin}
        trust={state.user["custom:trust"]}
        tiles={state.tiles}
        sections={preview_page_sections}
      />
      <div className="preview_button">
        <Button
          positive
          style={{ width: "100%" }}
          onClick={() => updateSlider()}
        >
          Hide Display
        </Button>
      </div>
      {preview_origin === "tiles" ? (
        <div className="preview_button">
          <Link
            style={{ width: "100%" }}
            className="ui button blue"
            to={"/tiles"}
          >
            Edit Tiles
          </Link>
        </div>
      ) : (
        <div className="preview_button">
          <Link
            style={{ width: "100%" }}
            className="ui button blue"
            to={"/sections?id=" + selected_page_id}
          >
            Edit page
          </Link>
        </div>
      )}
    </div>
  );
};

export {Slider};
